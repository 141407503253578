const numAbbr = (num) =>
	num <= 0
		? ""
		: numAbbr(Math.floor((num - 1) / 26)) +
		  String.fromCharCode(((num - 1) % 26) + 65);

const abbrNum = (abbr) =>
	abbr
		.toUpperCase()
		.split("")
		.reduce((acc, val) => acc * 26 + val.charCodeAt(0) - 64, 0);

function incrementAlphabet(str, num) {
	if (!str || !/^[A-Z]+$/.test(str)) {
		throw new Error(
			'Invalid input. Please provide a valid capital alphabet string like "AK".'
		);
	}

	if (typeof num !== "number") {
		throw new Error("Invalid input. Please provide a positive number.");
	}
	let n = abbrNum(str);
	n = n + num;
	return numAbbr(n);
}

export default incrementAlphabet;
