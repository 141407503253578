function Step({ children, heading, description }) {
	return (
		<div
			style={{
				backgroundColor: "#181818",
				padding: 24,
				borderRadius: 8,
				marginBottom: 24,
			}}
		>
			<h2>{heading}</h2>
			<p>{description}</p>
			{children}
		</div>
	);
}
export default Step;
