import Papa from "papaparse";
import addEmails from "./addEmails";

const parseCSV = (file) =>
	new Promise((resolve, reject) => {
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: resolve,
		});
	});

function getHumanReadableTimestamp() {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, "0");
	const day = String(now.getDate()).padStart(2, "0");
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const seconds = String(now.getSeconds()).padStart(2, "0");

	const timestamp = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
	return timestamp;
}

const downloadFile = (objs) => {
	const csv = Papa.unparse(objs);

	// Create a Blob containing the CSV data
	const blob = new Blob([csv], { type: "text/csv" });

	// Create a download link for the Blob
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = `codexty-leads-${getHumanReadableTimestamp()}.csv`; // Specify the file name

	// Trigger a click event on the download link
	a.click();

	// Clean up by revoking the Object URL
	URL.revokeObjectURL(url);
};

function StepOne() {
	const handleFiles = async (files) => {
		if (!files.length) {
			return undefined;
		}
		let ds = [];
		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			const res = await parseCSV(file);
			ds = [...ds, ...res.data];
		}
		const withEmails = addEmails(ds);
		console.log(
			withEmails.map((s) => {
				let t = {};
				for (let i = 1; i <= 18; i++) {
					t["Permutation " + i] = s["Permutation " + i];
				}
				return t;
			})
		);
		downloadFile(withEmails);
	};
	return (
		<div>
			<input
				id="uploadCaptureInputFile"
				type="file"
				accept=".csv"
				onChange={(e) => {
					handleFiles(e.target.files);
				}}
				multiple
			/>
		</div>
	);
}

export default StepOne;
