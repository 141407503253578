import Papa from "papaparse";

const parseCSV = (file) =>
	new Promise((resolve, reject) => {
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: resolve,
		});
	});

function getHumanReadableTimestamp() {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, "0");
	const day = String(now.getDate()).padStart(2, "0");
	const hours = String(now.getHours()).padStart(2, "0");
	const minutes = String(now.getMinutes()).padStart(2, "0");
	const seconds = String(now.getSeconds()).padStart(2, "0");

	const timestamp = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
	return timestamp;
}

const downloadFile = (objs) => {
	const csv = Papa.unparse(objs);

	// Create a Blob containing the CSV data
	const blob = new Blob([csv], { type: "text/csv" });

	// Create a download link for the Blob
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = `codexty-leads-with-emails-${getHumanReadableTimestamp()}.csv`; // Specify the file name

	// Trigger a click event on the download link
	a.click();

	// Clean up by revoking the Object URL
	URL.revokeObjectURL(url);
};

const createCols = (append) =>
	new Array(18)
		.fill(undefined)
		.map((_, index) => `Permutation ${index + 1}` + append);

const deleteKeysFromObject = (obj, keys) => {
	keys.forEach((key) => {
		delete obj[key];
	});
	return obj;
};

const cleanData = (objs) =>
	objs
		.map((obj) => {
			// ewww
			let temp = obj;
			const colsToDelete = [...createCols(""), ...createCols(" Copy")];
			temp = deleteKeysFromObject(temp, colsToDelete);
			const colsToSearch = createCols(" Compare").reverse();
			colsToSearch.forEach((key) => {
				if (temp[key]) {
					temp["Email"] = temp[key].toLowerCase();
				}
			});
			temp = deleteKeysFromObject(temp, colsToSearch);
			return temp;
		})
		.filter((lead) => lead["Email"]);

function StepThree() {
	const handleFile = async (file) => {
		const res = await parseCSV(file);
		const cleanedData = cleanData(res.data);
		downloadFile(cleanedData);
	};
	return (
		<div>
			<input
				id="uploadCaptureInputFile"
				type="file"
				accept=".csv"
				onChange={(e) => {
					handleFile(e.target.files[0]);
				}}
			/>
		</div>
	);
}

export default StepThree;
