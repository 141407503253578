import psl from "psl";
import incrementAlphabet from "./incrementAlphabet";

const EMAIL_PATTERNS = [
	"{firstnamefull}{lastnamefull}",
	"{firstnamechar}{lastnamefull}",
	"{firstnamefull}",
	"{lastnamefull}",
	"{firstnamefull}.{lastnamefull}",
	"{firstnamefull}{lastnamechar}",
	"{firstnamefull}.{lastnamechar}",
	"{firstnamefull}_{lastnamefull}",
	"{firstnamechar}.{lastnamefull}",
	"{lastnamefull}{firstnamefull}",
	"{lastnamefull}.{firstnamefull}",
	"{lastnamefull}{firstnamechar}",
	"{lastnamechar}{firstnamefull}",
	"{firstnamefull}-{lastnamefull}",
	"{firstnamechar}-{lastnamefull}",
	"{lastnamefull}-{firstnamefull}",
	"{firstnamechar}{lastnamechar}",
	"{firstnamechar}.{lastnamechar}",
];

function getStringReplacePattern(pattern, firstName, lastName) {
	if (!pattern || !firstName) {
		return null;
	}

	let result = pattern.replace("{firstnamefull}", firstName);
	result = result.replace("{firstnamechar}", firstName[0]);

	result = result.replace("{lastnamefull}", lastName);
	result = result.replace("{lastnamechar}", lastName[0]);

	return result;
}

function getUniqueKeys(arr) {
	return Object.keys(
		arr.reduce((prev, curr) => {
			return {
				...prev,
				...curr,
			};
		}, {})
	);
}

function isValidEmail(email) {
	const reg =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(String(email).toLowerCase());
}

const removeWwwAndProtocols = (inputString) => {
	if (!inputString) {
		return undefined;
	}
	let domain = inputString;
	domain = domain.replace(/^https?:\/\//, "");
	domain = domain.split("/")[0];
	if (!domain) {
		return undefined;
	}
	if (!psl.get(domain)) {
		console.log("if fail now bye", inputString);
	}
	return psl.get(domain);
};

const addEmailPermutations = (obj) => {
	const firstName = obj["First Name"].toLowerCase().trim();
	const lastName = obj["Last Name"].toLowerCase().trim();
	let domain = obj["Company Website"];
	domain = removeWwwAndProtocols(domain);
	if (firstName && lastName && domain) {
		let hasValidEmail = false;
		EMAIL_PATTERNS.forEach((pattern, i) => {
			let email = getStringReplacePattern(pattern, firstName, lastName);
			if (email) {
				email += "@" + domain;
				if (isValidEmail(email)) {
					obj["Permutation " + (i + 1)] = email;
					hasValidEmail = true;
				} else {
					obj["Permutation " + (i + 1)] = "";
				}
			}
		});
		if (hasValidEmail) return obj;
	}
};

const addCopyPermutations = (obj) => {
	for (let i = 1; i <= 18; i++) {
		obj["Permutation " + i + " Copy"] = obj["Permutation " + i];
	}
	return obj;
};

const PERMUTATION_COLUMN = "AK";
const PERMUTATION_COMPARE_COLUMN = "BC";

const addFormulas = (obj, index) => {
	for (let i = 1; i <= 18; i++) {
		obj["Permutation " + i + " Compare"] = `=IF(${incrementAlphabet(
			PERMUTATION_COLUMN,
			i - 1
		)}${index + 2}<>${incrementAlphabet(PERMUTATION_COMPARE_COLUMN, i - 1)}${
			index + 2
		}, ${incrementAlphabet(PERMUTATION_COMPARE_COLUMN, i - 1)}${
			index + 2
		}, "")`;
	}
	return obj;
};

const removeUndefined = (obj) => !!obj;

const addEmails = (r) => {
	return r
		.map(addEmailPermutations)
		.filter(removeUndefined)
		.map(addCopyPermutations)
		.map(addFormulas);
};

export default addEmails;
